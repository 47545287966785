import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getAvailabilityRequests } from "../src/modules/Booking/reducers";

const MustServiceSelectedRoute = ({ children, availabilityRequests }) => {
	return (
		<div>
			{availabilityRequests && availabilityRequests.length > 0 ? (
				<div>{children}</div>
			) : (
				<Redirect to="reasons" />
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	availabilityRequests: getAvailabilityRequests(state.booking),
});

export default connect(mapStateToProps)(MustServiceSelectedRoute);
