import React from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Drawer } from "antd";
import CardForm from "./CardForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import { closePaymentDrawer } from "./actions";
import { stripePublicKey } from "../../config";
// import { isMobile } from "react-device-detect";

function Payment({ practiceId, serviceTotal, surcharge, totalWithSurcharge }) {
	// const dispatch = useDispatch();
	// const visible = useSelector((state) => state.booking.paymentDrawerState);
	const stripePromise = loadStripe(stripePublicKey);

	return (
		<div>
			<Elements stripe={stripePromise}>
				<CardForm
					{...{ practiceId, serviceTotal, surcharge, totalWithSurcharge }}
				/>
			</Elements>
		</div>
	);
}

export default Payment;
