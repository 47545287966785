/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
	Menu,
	Dropdown,
	Button,
	Space,
	Tooltip,
	Typography,
	Avatar,
	Row,
} from "antd";
import {
	CheckCircleFilled,
	ClearOutlined,
	DownOutlined,
	SearchOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { isEmpty, filter, map, find } from "lodash";
import { green } from "@ant-design/colors";
import { primaryDark } from "../../GlobalConfig";

function ProfessionalSelectList({
	practitioners,
	appointmentReasonId,
	toggleItem,
	availabilityRequests,
}) {
	const menu = (
		<Menu>
			<Menu.ItemGroup title="Make selection" />
			<Menu.Item
				icon={
					<Avatar style={{ background: "black" }}>
						<SearchOutlined />
					</Avatar>
				}
				key="0"
				onClick={() => {
					const anyBatch = Date.now();
					map(practitioners, ({ id }) =>
						toggleItem({
							reasonId: appointmentReasonId,
							practitionerId: id,
							anyBatch,
						}),
					);
				}}
			>
				First Available
			</Menu.Item>
			{practitioners?.map(({ id, name, photo }) => (
				<Menu.Item
					icon={
						photo ? (
							<Avatar src={photo} />
						) : (
							<Avatar style={{ background: primaryDark }}>
								<UserOutlined />
							</Avatar>
						)
					}
					key={id}
					onClick={() =>
						toggleItem({
							reasonId: appointmentReasonId,
							practitionerId: id,
						})
					}
				>
					{name}
				</Menu.Item>
			))}
		</Menu>
	);

	const matchingRequests = filter(
		availabilityRequests,
		({ reasonId }) => appointmentReasonId === reasonId,
	);
	const isAnyRequest = filter(
		matchingRequests,
		({ anyBatch }) => anyBatch,
	).length;

	const requests = map(
		matchingRequests,
		({ practitionerId }) => practitionerId,
	);
	const practitioner = find(practitioners, ({ id }) => requests.includes(id));
	const isSelected = practitioner;

	return (
		<Row>
			{isSelected ? (
				<span>
					<>
						{isAnyRequest ? (
							<span>
								<CheckCircleFilled
									style={{ color: green[4] }}
									className="mr-sm"
								/>
								Anyone
								<br />
							</span>
						) : (
							<span>
								<CheckCircleFilled
									style={{ color: green[4] }}
									className="mr-sm"
								/>

								{practitioner?.name}
								<br />
							</span>
						)}
					</>
					<Tooltip title="Remove Selection">
						<Typography.Link
							type="secondary"
							onClick={() =>
								toggleItem({
									reasonId: appointmentReasonId,
									practitionerId: requests[0],
								})
							}
						>
							<Space>
								<ClearOutlined />
								Clear
							</Space>
						</Typography.Link>
					</Tooltip>
				</span>
			) : (
				<Dropdown
					overlay={menu}
					trigger={["click"]}
					disabled={!isEmpty(availabilityRequests)}
				>
					<Button
						disabled={!isEmpty(availabilityRequests)}
						block
						onClick={(e) => e.preventDefault()}
					>
						Select <DownOutlined />
					</Button>
				</Dropdown>
			)}
		</Row>
	);
}
export default ProfessionalSelectList;
